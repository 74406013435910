.landing {
  width: 100%;
  height: 100%;
  background-color: blanchedalmond;
}

.landingHeader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: blanchedalmond;
}

#homeContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: blanchedalmond;
  scrollbar-width: none;
}

#homeContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#fixedTop {
  position: fixed;
  top: 0;
  width: 100%;
  display: flex;
  height: 50px;
  max-height: 50px;

  justify-content: center;
  align-items: flex-start;
}

#topSection {
  width: 100%;
  display: flex;
  min-height: 50px;

  justify-content: center;
  align-items: flex-start;
  white-space: normal;
}

.spaceFiller {
  height: 150px;
  width: 100%;
  background-color: blanchedalmond;
}

.topSpaceFiller {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

#subTextContent {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  padding: 0 20px 20px 20px;
  text-align: center;
  color: slategrey;
  font-weight: bold;
  font-size: 20px;
}

.topLogin {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
}

.landing > #homeButtonWrap {
  position: fixed;
  top: 0;
  color: slategrey;
}

#toApp {
  font-weight: bolder;
  max-height: 50px;
  white-space: nowrap;
}

#toNew {
  font-weight: bolder;
  max-height: 50px;
  white-space: nowrap;
  color: darkslategrey;
  background-color: #83b799;
  border-color: darkslategrey;
}

#toNew:hover {
  color: darkslategrey;
  background-color: blanchedalmond;
}

#invis {
  opacity: 0;
}

#introText {
  max-height: 50px;
  margin: auto 10px;
  font-size: 25px;
  color: slategrey;
}

#topSection > #introText {
  max-height: fit-content;
}

#fadeText {
  opacity: 0;
}

#homeLogin {
  display: flex;
  justify-content: center;
  align-items: center;;
  background-color: #548278;
  border-color: #548278;
  border-radius: 10px;
}

.toAppButton {
  padding: 0 10px;
  margin: 10px;
  border: 3px solid #c26860;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  height: auto;
  width: auto;
  border-radius: 10px;
  color: blanchedalmond;
  background-color: #c26860;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

.toAppButton:hover {
  color: #c26860;
  background-color: blanchedalmond;
}

#homeLogin:hover {
  color: #548278;
  background-color: blanchedalmond;
}

#botSection {
  height: 300px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
}

#socialIcons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.bigIco {
  opacity: 0.8;
  transform: scale(5);
  margin: 10px 20px;
  height: 90px;
  width: 90px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

#lastSection {
  height: 200px;
  background-color: gray;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 10px;
  text-align: center;
}

.iconsWrap {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.2;
}

.iconDesigns {
  position: absolute;
  top: 150px;
  right: 100px;
  color: slategrey;
  opacity: 1;
  pointer-events: none;
}

#listOutline {
  transform: scale(10);
}

#checkBlank {
  transform: scale(10);
  top: 250px;
  right: 300px;
}

#checkYes {
  transform: scale(10);
  top: 200px;
  right: 600px;
}

#speaker {
  transform: scale(10);
  top: 350px;
  right: 500px;
}

#note {
  transform: scale(10);
  top: 80px;
  right: 500px;
}

#midSection {
  height: fit-content;
  background-color: slategrey;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
}

.midWrap {
  margin: 10px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.smalMid {
  margin: 10px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.smalImg {
  max-height: 200px;
  border: 1px solid slategrey;
  border-radius: 10px;
}

.imgCont {
  max-height: 400px;
  border: 1px solid slategrey;
  border-radius: 10px;

}

.midText {
  margin: 40px;
  font-size: 25px;
  color: blanchedalmond;
  font-weight: bolder;
}

.bottomSupport {
  text-align: center;
}