#mainWrapper {
  background-color: slategrey;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
}

#appHeader {
  background-color: blanchedalmond;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.headerContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  color: slategrey;
  background-color: blanchedalmond;
  display: inline-flex;
  align-items: flex-start;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  border-bottom: 2px solid slategrey;
}

.headerDivider {
  min-width: 2px;
  background-color: slategrey;
  height: 100%;
}

#headerLoader {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

#loaderWrap {
  min-width: 50px;
  width: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

#betaSub {
  font-size: 10px;
}

#emptyBoard {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: slategrey;
  color: blanchedalmond;
  width: 100%;
  height: 100%;
  white-space: normal;
  padding-left: 70px;
  padding-right: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}