.loginScreen {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: flex-start;
}

.loginLeft {
  background-color: slategrey;
  max-width: 70%;
  flex-grow: 1;
  height: 100%;
}

.loginRight {
  padding: 0 20px;
  background-color: blanchedalmond;
  min-width: 30%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.loginEntryBox {
  margin: 5px;
  padding: 10px;
  border: 1px solid slategrey;
  border-radius: 5px;
  width: 300px;
  height: 50px;
  background-color: blanchedalmond;
}

.linkContainer {
  margin: 5px;
  width: 300px;
  height: 50px;
  background-color: blanchedalmond;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  flex-wrap: nowrap;
  align-items: center;
}

.bottomLinkContainer {
  margin: 5px;
  margin-top: 100px;
  width: 300px;
  height: 60px;
  background-color: blanchedalmond;
  display: flex;
  flex-direction: column;
  justify-self: flex-end;
  justify-content: space-between;
}


#loginButtons {
  align-self: center;
  color: slategrey;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  border-radius: 5px;
}

#loginButtons:hover {
  color: blanchedalmond;
  background-color: slategrey;
}

#createButton {
  background-color: slategrey;
  align-self: center;
  color: blanchedalmond;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid slategray;
  border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

#createButton:hover {
  color: slategrey;
  background-color: blanchedalmond;
}

#loginTitle {
  font-weight: bolder;
  font-size: 50px;
  color: slategrey;
  margin-bottom: 50px;
  text-align: center;
}

.loader {
  border: 16px solid slategrey;
  border-top: 16px solid blanchedalmond;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}