#newBoardButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 36px;
  height: 30px;
  background-color: blanchedalmond;
}

#clearButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #d9534f;
  width: 40px;
  color: black;
  border: 2px solid black;
}

.cbutton {
  border: 2px solid slategrey;
  border-radius: 10px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

.undoButton {
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 30px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: blanchedalmond;
  color: slategrey;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

#delToastButtons {
  display: flex;
  justify-content: flex-start;
}

#delConf {
  width: 70px;
  margin-left: 20px;
  background-color: #d9534f;
  color: black;
}

.cbutton:hover, .undoButton:hover, #newBoardButton:hover, #clearButton:hover, #delConf:hover {
  background-color: slategrey;
  color: blanchedalmond;
}

#homeButton {
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
}

#homeButtonWrap {
  cursor: pointer;
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebutton {
  opacity: inherit;
  border: 2px solid slategrey;
  color: slategrey;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

#plusButton {
  top: 10px;
}

#minusButton {
  top: 60px;
}

#delButton {
  top: 110px;
}

#logoutButton {
  bottom: 0;
}

.sidebutton:hover {
  background-color: slategrey;
  color: blanchedalmond;
}

.boardButtonWrap {
  background-color: blanchedalmond;
  width: 146px;
  height: 46px;
  border-radius: 10px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}

.boardButton {
  height: 30px;
  width: 30px;
  margin: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  border: 2px solid slategrey;
  border-radius: 10px;
  background-color: blanchedalmond;
  color: slategrey;
}

.boardButton:hover {
  background-color: slategrey;
  color: blanchedalmond;
}