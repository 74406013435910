#boardList, #noBoards {
  width: 100%;
  margin-top: 10px;
}

#noBoards {
  display: flex;
  justify-content: center;
  align-self: flex-start;
}

.invisText {
  opacity: 0;
}

#boardList {
  overflow-y: hidden;
  overflow-x: scroll;
  display: block;
  align-items: center;
  justify-content: flex-start;
  height: 40px;
  scrollbar-width: none;
  background-color: blanchedalmond;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  white-space: nowrap;
  border-bottom: 2px solid slategrey;
}

#boardList:hover {
  height: 140px;
  border-bottom-right-radius: 10px;
}

#boardList::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.boardWrapper {
  margin: 0 5px 0 5px;
  border: 2px solid slategrey;
  border-radius: 10px;
  width: 150px;
  height: 130px;
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

#boardTextArea {
  font-family: 'Inconsolata', serif;
  background-color: inherit;
  border: none;
  resize: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

.boardName {
  max-width: 140px;
  color: black;
  display: block;
  white-space: normal;
  padding: 5px;
  height: 80px;
}

#selectedBoard {
  background-color: slategrey;
}

#selectedBoardName {
  color: blanchedalmond;
}
