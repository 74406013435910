.react-draggable {
  display: block;
  float: left;
}

.box, .boxOutline {
  background-color: blanchedalmond;
  display: block;
  position: absolute;
  border-radius: 10px;
}

.boxOutline {
  opacity: .5;
}

#textArea {
  opacity: inherit;
  font-family: 'Inconsolata', serif;
  background-color: inherit;
  margin-left: 1px;
  margin-top: 1px;
  border-radius: 10px;
  border: none;
  resize: none;
  outline: none;
  -webkit-box-shadow: none;
  -moz-box-shadow: none;
  box-shadow: none;
}

#rotIcon {
  transform: rotate(180deg);
}

.tooltiptext {
  visibility: hidden;
  opacity: 0;
  background-color: slategrey;
  width: 230px;
  color: blanchedalmond;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 160%;
  height: 30px;
  border: 1px blanchedalmond solid;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  transition-delay: 0.5s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent blanchedalmond transparent transparent;
}

.sidebutton:hover .tooltiptext  {
  opacity: 1;
  visibility: visible;
}