html, body {
  height: 100%;
  width: 100%;
}

#mainBody {
  margin: 0;
  padding: 0;
  font-family: 'Inconsolata', monospace;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#mainWrapper {
  background-color: slategrey;
  display: block;
  position: absolute;
  top: 40px;
  left: 0;
  width: 100%;
  height: calc(100% - 40px);
  overflow: hidden;
}

#appHeader {
  background-color: blanchedalmond;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
}

.headerContainer {
  position: absolute;
  top: 0;
  left: 0;
  height: 50px;
  width: 100%;
  color: slategrey;
  background-color: blanchedalmond;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  border-bottom: 2px solid slategrey;
}

.headerDivider {
  min-width: 2px;
  background-color: slategrey;
  height: 100%;
}

#headerLoader {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
}

#loaderWrap {
  min-width: 50px;
  width: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#betaSub {
  font-size: 10px;
}

#emptyBoard {
  text-align: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  background-color: slategrey;
  color: blanchedalmond;
  width: 100%;
  height: 100%;
  white-space: normal;
  padding-left: 70px;
  padding-right: 20px;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
#newBoardButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  width: 36px;
  height: 30px;
  background-color: blanchedalmond;
}

#clearButton {
  margin-top: 10px;
  margin-left: 10px;
  margin-right: 10px;
  background-color: #d9534f;
  width: 40px;
  color: black;
  border: 2px solid black;
}

.cbutton {
  border: 2px solid slategrey;
  border-radius: 10px;
  height: 30px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

.undoButton {
  border: none;
  border-radius: 10px;
  width: 50px;
  height: 30px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  background-color: blanchedalmond;
  color: slategrey;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

#delToastButtons {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

#delConf {
  width: 70px;
  margin-left: 20px;
  background-color: #d9534f;
  color: black;
}

.cbutton:hover, .undoButton:hover, #newBoardButton:hover, #clearButton:hover, #delConf:hover {
  background-color: slategrey;
  color: blanchedalmond;
}

#homeButton {
  margin-left: auto;
  margin-right: auto;
  font-size: 25px;
}

#homeButtonWrap {
  cursor: pointer;
  min-width: 50px;
  width: 50px;
  height: 50px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.sidebutton {
  opacity: inherit;
  border: 2px solid slategrey;
  color: slategrey;
  border-radius: 10px;
  width: 30px;
  height: 30px;
  margin: 10px;
  cursor: pointer;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  position: absolute;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  left: 0;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

#plusButton {
  top: 10px;
}

#minusButton {
  top: 60px;
}

#delButton {
  top: 110px;
}

#logoutButton {
  bottom: 0;
}

.sidebutton:hover {
  background-color: slategrey;
  color: blanchedalmond;
}

.boardButtonWrap {
  background-color: blanchedalmond;
  width: 146px;
  height: 46px;
  border-radius: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-align-items: center;
          align-items: center;
}

.boardButton {
  height: 30px;
  width: 30px;
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  border: 2px solid slategrey;
  border-radius: 10px;
  background-color: blanchedalmond;
  color: slategrey;
}

.boardButton:hover {
  background-color: slategrey;
  color: blanchedalmond;
}
.Main {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: inherit;
}


#sideBar {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 50px;
  height: 100%;
  background-color: blanchedalmond;
}
.react-draggable {
  display: block;
  float: left;
}

.box, .boxOutline {
  background-color: blanchedalmond;
  display: block;
  position: absolute;
  border-radius: 10px;
}

.boxOutline {
  opacity: .5;
}

#textArea {
  opacity: inherit;
  font-family: 'Inconsolata', serif;
  background-color: inherit;
  margin-left: 1px;
  margin-top: 1px;
  border-radius: 10px;
  border: none;
  resize: none;
  outline: none;
  box-shadow: none;
}

#rotIcon {
  -webkit-transform: rotate(180deg);
          transform: rotate(180deg);
}

.tooltiptext {
  visibility: hidden;
  opacity: 0;
  background-color: slategrey;
  width: 230px;
  color: blanchedalmond;
  text-align: center;
  border-radius: 5px;
  position: absolute;
  z-index: 20;
  top: 0;
  left: 160%;
  height: 30px;
  border: 1px blanchedalmond solid;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  transition: opacity 0.5s;
  -webkit-transition: opacity 0.5s;
  -webkit-transition-delay: 0.5s;
          transition-delay: 0.5s;
}

.tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent blanchedalmond transparent transparent;
}

.sidebutton:hover .tooltiptext  {
  opacity: 1;
  visibility: visible;
}
#boardList, #noBoards {
  width: 100%;
  margin-top: 10px;
}

#noBoards {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-self: flex-start;
          align-self: flex-start;
}

.invisText {
  opacity: 0;
}

#boardList {
  overflow-y: hidden;
  overflow-x: scroll;
  display: block;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  height: 40px;
  scrollbar-width: none;
  background-color: blanchedalmond;
  transition: height 0.5s;
  -webkit-transition: height 0.5s;
  white-space: nowrap;
  border-bottom: 2px solid slategrey;
}

#boardList:hover {
  height: 140px;
  border-bottom-right-radius: 10px;
}

#boardList::-webkit-scrollbar {
  width: 1px;
  height: 1px;
}

.boardWrapper {
  margin: 0 5px 0 5px;
  border: 2px solid slategrey;
  border-radius: 10px;
  width: 150px;
  height: 130px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  cursor: pointer;
}

#boardTextArea {
  font-family: 'Inconsolata', serif;
  background-color: inherit;
  border: none;
  resize: none;
  outline: none;
  box-shadow: none;
}

.boardName {
  max-width: 140px;
  color: black;
  display: block;
  white-space: normal;
  padding: 5px;
  height: 80px;
}

#selectedBoard {
  background-color: slategrey;
}

#selectedBoardName {
  color: blanchedalmond;
}

.landing {
  width: 100%;
  height: 100%;
  background-color: blanchedalmond;
}

.landingHeader {
  position: fixed;
  top: 0;
  width: 100%;
  height: 50px;
  background-color: blanchedalmond;
}

#homeContainer {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: blanchedalmond;
  scrollbar-width: none;
}

#homeContainer::-webkit-scrollbar {
  width: 0;
  height: 0;
}

#fixedTop {
  position: fixed;
  top: 0;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  height: 50px;
  max-height: 50px;

  -webkit-justify-content: center;

          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
}

#topSection {
  width: 100%;
  display: -webkit-flex;
  display: flex;
  min-height: 50px;

  -webkit-justify-content: center;

          justify-content: center;
  -webkit-align-items: flex-start;
          align-items: flex-start;
  white-space: normal;
}

.spaceFiller {
  height: 150px;
  width: 100%;
  background-color: blanchedalmond;
}

.topSpaceFiller {
  height: 200px;
  width: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#subTextContent {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  padding: 0 20px 20px 20px;
  text-align: center;
  color: slategrey;
  font-weight: bold;
  font-size: 20px;
}

.topLogin {
  position: fixed;
  top: 0;
  left: 0;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
  -webkit-align-items: center;
          align-items: center;
  width: 100%;
}

.landing > #homeButtonWrap {
  position: fixed;
  top: 0;
  color: slategrey;
}

#toApp {
  font-weight: bolder;
  max-height: 50px;
  white-space: nowrap;
}

#toNew {
  font-weight: bolder;
  max-height: 50px;
  white-space: nowrap;
  color: darkslategrey;
  background-color: #83b799;
  border-color: darkslategrey;
}

#toNew:hover {
  color: darkslategrey;
  background-color: blanchedalmond;
}

#invis {
  opacity: 0;
}

#introText {
  max-height: 50px;
  margin: auto 10px;
  font-size: 25px;
  color: slategrey;
}

#topSection > #introText {
  max-height: -webkit-fit-content;
  max-height: -moz-fit-content;
  max-height: fit-content;
}

#fadeText {
  opacity: 0;
}

#homeLogin {
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;;
  background-color: #548278;
  border-color: #548278;
  border-radius: 10px;
}

.toAppButton {
  padding: 0 10px;
  margin: 10px;
  border: 3px solid #c26860;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  font-size: 15px;
  height: auto;
  width: auto;
  border-radius: 10px;
  color: blanchedalmond;
  background-color: #c26860;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

.toAppButton:hover {
  color: #c26860;
  background-color: blanchedalmond;
}

#homeLogin:hover {
  color: #548278;
  background-color: blanchedalmond;
}

#botSection {
  height: 300px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
}

#socialIcons {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.bigIco {
  opacity: 0.8;
  -webkit-transform: scale(5);
          transform: scale(5);
  margin: 10px 20px;
  height: 90px;
  width: 90px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

#lastSection {
  height: 200px;
  background-color: gray;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 10px;
  text-align: center;
}

.iconsWrap {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0.2;
}

.iconDesigns {
  position: absolute;
  top: 150px;
  right: 100px;
  color: slategrey;
  opacity: 1;
  pointer-events: none;
}

#listOutline {
  -webkit-transform: scale(10);
          transform: scale(10);
}

#checkBlank {
  -webkit-transform: scale(10);
          transform: scale(10);
  top: 250px;
  right: 300px;
}

#checkYes {
  -webkit-transform: scale(10);
          transform: scale(10);
  top: 200px;
  right: 600px;
}

#speaker {
  -webkit-transform: scale(10);
          transform: scale(10);
  top: 350px;
  right: 500px;
}

#note {
  -webkit-transform: scale(10);
          transform: scale(10);
  top: 80px;
  right: 500px;
}

#midSection {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  background-color: slategrey;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
  padding: 20px 0;
}

.midWrap {
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.smalMid {
  margin: 10px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.smalImg {
  max-height: 200px;
  border: 1px solid slategrey;
  border-radius: 10px;
}

.imgCont {
  max-height: 400px;
  border: 1px solid slategrey;
  border-radius: 10px;

}

.midText {
  margin: 40px;
  font-size: 25px;
  color: blanchedalmond;
  font-weight: bolder;
}

.bottomSupport {
  text-align: center;
}
.loginScreen {
  width: 100vw;
  height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.loginLeft {
  background-color: slategrey;
  max-width: 70%;
  -webkit-flex-grow: 1;
          flex-grow: 1;
  height: 100%;
}

.loginRight {
  padding: 0 20px;
  background-color: blanchedalmond;
  min-width: 30%;
  height: 100%;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.loginEntryBox {
  margin: 5px;
  padding: 10px;
  border: 1px solid slategrey;
  border-radius: 5px;
  width: 300px;
  height: 50px;
  background-color: blanchedalmond;
}

.linkContainer {
  margin: 5px;
  width: 300px;
  height: 50px;
  background-color: blanchedalmond;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  -webkit-flex-wrap: nowrap;
          flex-wrap: nowrap;
  -webkit-align-items: center;
          align-items: center;
}

.bottomLinkContainer {
  margin: 5px;
  margin-top: 100px;
  width: 300px;
  height: 60px;
  background-color: blanchedalmond;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  justify-self: flex-end;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}


#loginButtons {
  -webkit-align-self: center;
          align-self: center;
  color: slategrey;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
  border-radius: 5px;
}

#loginButtons:hover {
  color: blanchedalmond;
  background-color: slategrey;
}

#createButton {
  background-color: slategrey;
  -webkit-align-self: center;
          align-self: center;
  color: blanchedalmond;
  cursor: pointer;
  padding-left: 10px;
  padding-right: 10px;
  border: 1px solid slategray;
  border-radius: 5px;
  transition: background-color 0.5s;
  -webkit-transition: background-color 0.5s;
}

#createButton:hover {
  color: slategrey;
  background-color: blanchedalmond;
}

#loginTitle {
  font-weight: bolder;
  font-size: 50px;
  color: slategrey;
  margin-bottom: 50px;
  text-align: center;
}

.loader {
  border: 16px solid slategrey;
  border-top: 16px solid blanchedalmond;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  -webkit-animation: spin 2s linear infinite;
          animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}

@keyframes spin {
  0% { -webkit-transform: rotate(0deg); transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); transform: rotate(360deg); }
}
