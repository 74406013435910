.Main {
  display: block;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  background-color: inherit;
}


#sideBar {
  position: absolute;
  display: block;
  top: 0;
  left: 0;
  width: 50px;
  height: 100%;
  background-color: blanchedalmond;
}